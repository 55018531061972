<template lang="pug">
article.mission-card
  .mission-card__icon
  h4.mission-card__title Coacher indeividuellement
  p.mission-card__description Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also
</template>

<script>
export default {
  name: 'MissionCard'
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors';
.mission-card {
  height: 300px;
  width: 300px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  &__icon {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: $darkBlue;
  }

  &__title {
    font-family: 'Hind Vadodara', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
  }

  &__description {
    text-align: center;
    font-family: 'Libre Franklin', sans-serif;
    font-size: 0.9rem;
  }
}
</style>
